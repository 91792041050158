import React, { useState } from "react"
import clsx from "clsx"
import Layout from "@components/Layout/Layout";
import Container from "@components/Container/Container.jsx";
import Button from "@components/Buttons/Button.jsx"
import MainWrap from "@components/Wrap/MainWrap";
import { Typography } from "@material-ui/core"
import { Grid } from "@material-ui/core";
import { makeComponentStyles } from "@components/mixins.js"
import { graphql } from "gatsby";
import Markdown from "markdown-to-jsx";
import { useIntl } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "../heplers.js"
import FloatWrap from "@components/Wrap/FloatWrap.jsx";
import SimpleSlider from "@components/Slider/Slider";
import { SwiperSlide } from "swiper/react";
import Seo from "@components/seo";
import ButtonLink from "@components/Buttons/ButtonLink"

const styles = ({ palette, breakpoints }) => ({
  text: {
    fontSize: "18px",
    // color: palette.text.primary,
    color: palette.text.black,
    '& $b': {
      fontWeight: 500
    },
    [breakpoints.down('sm')]: {
      fontSize: "14px",
    }
  },
  linker: {
    color: palette.primary.main,
  },
  swiper_slide: {
    background: palette.primary.light,
    padding: "30px",
    textAlign: "center",
    [breakpoints.down('sm')]: {
      padding: "20px 24px",
    }
  },
  shop_slide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: "center",
    height: '100%',
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: "center",
    background: palette.primary.light,
    [breakpoints.down('sm')]: {
      padding: 0,
    },
    [breakpoints.up('md')]: {
      padding: '20px 24px',
      height: '100%',
    }
  }
});

function go(link) {
  window.open(link, "_blank")
}

const Clothes = ({ data }) => {
  const {
    text,
    linker,
    swiper_slide,
    shop_slide,
    card
  } = makeComponentStyles(styles);

  const [visible, setVisible] = useState(false);
  const intl = useIntl();
  const {
    Image,
    MoreInfo,
    Info,
    Shop,
    ElectronicInfo,
    ElectronicShop,
    OtherInfo,
    OtherShop,
    ClothesSEO,
  } = getStrapiContentByLang(data.allStrapiClothes.edges, intl.locale);

  return (
    <div>
      <Seo title={ClothesSEO?.TitleSEO}
           description={ClothesSEO?.DescriptionSEO}
           keywords={ClothesSEO?.KeywordsSEO.split(',')}
      />

      <>
        <Layout>
          <Container>
            <MainWrap>
              <MainWrap.Header>
                <Typography component={"h1"} variant={"h2"}>
                  {intl.formatMessage({ id: "purchases" })}
                </Typography>
              </MainWrap.Header>
              <MainWrap.Body>
                <Typography component={"h1"} variant={"h3"} style={{paddingBottom: "30px"}}>
                  {intl.formatMessage({ id: "clothes" })}
                </Typography>
                <FloatWrap className={text} Img={Image.localFile.publicURL} Float='right' breakpoint='sm'>
                  <Markdown>{Info}</Markdown>
                  <div hidden={!visible} style={{ marginTop: '20px' }}>
                    <Markdown>{MoreInfo}</Markdown>
                  </div>
                  <div style={{ marginTop: '20px' }} align='center'>
                    <Button onClick={() => setVisible(!visible)}>{visible ? intl.formatMessage({ id: "hide" }) : intl.formatMessage({ id: "show" })}</Button>
                  </div>
                </FloatWrap>
                <Grid container spacing={2} style={{ paddingTop: '80px' }}>
                  <Grid item xs={12}>
                    <SimpleSlider>
                      {
                        Shop.map(({ ShopName, ShopAddress, SiteLink }) => (
                          <SwiperSlide className={swiper_slide}>
                            <div className={shop_slide}>
                              <Typography className={text}><b>{ShopName}</b></Typography>
                              {
                                ShopAddress.map(({Address, Phone}) => (
                                  <Typography className={text}>
                                    {Address}<br/>
                                    {
                                      Phone
                                        ? <Typography className={text}>{Phone}</Typography>
                                        : null
                                    }
                                  </Typography>

                                ))
                              }
                              {
                                SiteLink
                                  ? <ButtonLink onClick={() => go(SiteLink)}>
                                      <Typography className={clsx(text, linker)}><b>{SiteLink}</b></Typography>
                                    </ButtonLink>
                                  : null
                              }
                            </div>
                          </SwiperSlide>
                        ))
                      }
                    </SimpleSlider>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ paddingTop: '80px' }}>
                  <Grid item xs={12}>
                    <Typography component={"h1"} variant={"h3"} style={{paddingBottom: "30px"}}>
                      {intl.formatMessage({ id: "electronic" })}
                    </Typography>
                    <Typography className={text} style={{paddingBottom: "30px"}}>
                      <Markdown>{ElectronicInfo}</Markdown>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleSlider>
                      {
                        ElectronicShop.map(({ ShopName, ShopAddress, SiteLink }) => (
                          <SwiperSlide className={swiper_slide}>
                            <div className={shop_slide}>
                              <Typography className={text}><b>{ShopName}</b></Typography>
                              {
                                ShopAddress.map(({Address, Phone}) => (
                                  <Typography className={text}>
                                    {Address}<br/>
                                    {
                                      Phone
                                        ? <Typography className={text}>{Phone}</Typography>
                                        : null
                                    }
                                  </Typography>

                                ))
                              }
                              {
                                SiteLink
                                  ? <ButtonLink onClick={() => go(SiteLink)}>
                                    <Typography className={clsx(text, linker)}><b>{SiteLink}</b></Typography>
                                  </ButtonLink>
                                  : null
                              }
                            </div>
                          </SwiperSlide>
                        ))
                      }
                    </SimpleSlider>
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ paddingTop: '80px' }}>
                  <Grid item xs={12}>
                    <Typography component={"h1"} variant={"h3"} style={{paddingBottom: "30px"}}>
                      {intl.formatMessage({ id: "other" })}
                    </Typography>
                    <Typography className={text} style={{paddingBottom: "30px"}}>
                      <Markdown>{OtherInfo}</Markdown>
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <SimpleSlider>
                      {
                        OtherShop.map(({ ShopName, ShopAddress, SiteLink }) => (
                          <SwiperSlide className={swiper_slide}>
                            <div className={shop_slide}>
                              <Typography className={text}><b>{ShopName}</b></Typography>
                              {
                                ShopAddress.map(({Address, Phone}) => (
                                  <Typography className={text}>
                                    {Address}<br/>
                                    {
                                      Phone
                                        ? <Typography className={text}>{Phone}</Typography>
                                        : null
                                    }
                                  </Typography>

                                ))
                              }
                              {
                                SiteLink
                                  ? <ButtonLink onClick={() => go(SiteLink)}>
                                      <Typography className={clsx(text, linker)}><b>{SiteLink}</b></Typography>
                                    </ButtonLink>
                                  : null
                              }
                            </div>
                          </SwiperSlide>
                        ))
                      }
                    </SimpleSlider>
                  </Grid>
                </Grid>
              </MainWrap.Body>
            </MainWrap>
          </Container>
        </Layout>
      </>
    </div>
  )
}

export default Clothes;
export const pageQuery = graphql`{
  allStrapiClothes {
    edges {
      node {
        Info
        MoreInfo
        locale
        Image {
          localFile {
            publicURL
          }
        }
        Shop {
          ShopName
          SiteLink
          ShopAddress {
            Address
            Phone
          }
        }
        ElectronicInfo
        ElectronicShop {
          SiteLink
          ShopName
          ShopAddress {
            Phone
            Address
          }
        }
        OtherInfo
        OtherShop {
          SiteLink
          ShopName
          ShopAddress {
            Address
            Phone
          }
        }
        ClothesSEO {
          DescriptionSEO
          KeywordsSEO
          TitleSEO
        }
      }
    }
  }
}`
